@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-xl {
    width: 8.5rem;
}

@layer base {
    @font-face {
        font-family: 'Sofia Pro';
        font-weight: 400;
        font-display: swap;
        src: url(assets/fonts/SofiaPro400.woff2) format('woff2');
    }
    @font-face {
        font-family: 'Sofia Pro';
        font-weight: 700;
        font-display: swap;
        src: url(assets/fonts/SofiaPro700.woff2) format('woff2');
    }
    @font-face {
        font-family: 'Sofia Pro';
        font-weight: 800;
        font-display: swap;
        src: url(assets/fonts/SofiaPro800.woff2) format('woff2');
    }
}